/**
 * Colour contrast
 */
// Read colour setting from Cookie and apply interval
var selectedContrast = 'default-contrast';
var contrastCookie = Cookies.get('colour-contrast');
if( undefined !== contrastCookie ) {
    selectedContrast = contrastCookie;
    if( 'default-contrast' != selectedContrast ) {
        setColourContrast( selectedContrast );
    }
}

jQuery('button.contrast-button-toggle').on('click', function() {
    selectedContrast = getNextColourContrast( selectedContrast );
    Cookies.set( 'colour-contrast', selectedContrast );
    setColourContrast( selectedContrast );
});

/**
 * Text size adjustment
 */
jQuery('button.text-size-toggle').on('click', function () {
    var bodyEl = jQuery('body');
    var currentSize = getCurrentTextSize();
    switch(jQuery(this).data('direction')) {
        case 'decrease':
            bodyEl.css('font-size', '-=0.1rem');
            Cookies.set( 'text-size', --currentSize );
            break;
        case 'reset':
            bodyEl.css('font-size', '');
            Cookies.set( 'text-size', 0 );
            break;
        case'increase':
            bodyEl.css('font-size', '+=0.1rem')
            Cookies.set( 'text-size', ++currentSize );
            break;
        default:
            console.log("Text toggle: Invalid direction");
    }
});


function getCurrentTextSize() {
    var textSizeCookie = Cookies.get('text-size');
    var selectedTextSize = 0;
    if( undefined !== textSizeCookie ) {
        selectedTextSize = textSizeCookie;
    }
    return selectedTextSize;
}

var currentTextSize = getCurrentTextSize();
currentTextSize = parseInt(currentTextSize);
if( getCurrentTextSize() !== 0 ) {
    setTextSize( currentTextSize );
}


function setTextSize(size) {
    console.log(size);
    var bodyEl = jQuery('body');
    bodyEl.css('font-size', '');
    if( size == 0 )
    {
        console.log('A');
        return true;
    } else if ( size > 0 ) {
        console.log('B');
        newSize = 1.1 + (0.1 * size);
        bodyEl.css('font-size', newSize+'rem');
        return true;
    } else {
        console.log('C');
        console.log(size);
        newSize = (1.1 - (-0.1 * size)).toFixed(1);
        console.log(newSize);
        bodyEl.css('font-size', newSize+'rem');
    }
}

/**
 *  Voice search
 */
// Show search by voice button if device can handle it
if (window.hasOwnProperty('webkitSpeechRecognition')) {
    console.log("Device supports speech recognition");
    jQuery('.search-by-voice').show();
}


jQuery('.search-by-voice').on('click', function() {

    // Double check their device handles this
    if (!window.hasOwnProperty('webkitSpeechRecognition')) {
        alert("Sorry, it looks like this device doesn't support this feature");
        return false;
    }

    var recognition = new webkitSpeechRecognition();

    recognition.continuous = false;
    recognition.interrimResults = false;
    recognition.lang = 'en-GB';
    jQuery('.search-by-voice-icon-path').css({ fill: "#26993f" });
    recognition.start();
    recognition.onresult = function(e) {
        var searchTranscript = e.results[0][0].transcript;
        jQuery('#s').val( searchTranscript );
        console.log( "We heard " + searchTranscript );
        recognition.stop();
        jQuery('.search-by-voice-icon-path').css({ fill: "#000000" });
        jQuery('#searchform').trigger('submit'); //.submit();
    }
    recognition.onerror = function(e) {
        //alert('Something went wrong with Search by Voice');
        recognition.stop();
        jQuery('.search-by-voice-icon-path').css({ fill: "#CA4032" });
        window.setTimeout(function (){ jQuery('.search-by-voice-icon-path').css({ fill: "#000000" }); }, 2000);
    }

});


function setColourContrast( selectedContrast ) {
    jQuery('body').removeClass('default-contrast').removeClass('white-and-black-contrast').removeClass('yellow-and-black-contrast').removeClass('black-and-yellow-contrast');
    jQuery('body').addClass( selectedContrast );
}

function getNextColourContrast( contrast ) {
    var colourContrastOptions = [
        'default-contrast',
        'white-and-black-contrast',
        'yellow-and-black-contrast',
        'black-and-yellow-contrast'
    ];
    var nextColourContrastPosition = ( (jQuery.inArray( contrast, colourContrastOptions ) + 1) % colourContrastOptions.length );
    return colourContrastOptions[nextColourContrastPosition];
}