jQuery(document).ready(function ($) {

    $(".collapsible_content_wrap__content__title").click(function () {
        $(this).toggleClass("collapsible-open").next().slideToggle();
    });

    $('.hamburger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('nav-close, is-active');
        $('body').toggleClass('nav-open');
    });

    $( ".header-sub-nav li.menu-item-has-children" ).hover(
        function() {
            $( '.sh__content' ).css('z-index', '8888');
        }, function() {
            $( '.sh__content' ).css('z-index', 'auto');
        }
    );

    /* if ($('.scroll-link').length) {
        $('.scroll-link a').on('click', function (e) {
            e.preventDefault();

            var target = $(this).attr('href');
            $('html, body').animate({
                'scrollTop': $(target).offset().top - 50
            }, 1000);

        });
    } */

    if ($(window).width() < 992) {

        $('.sub-menu').prepend('<ul class="hamburger-back"><li><a href="#" class="menu-back"><b><i class="far fa-angle-left"></i> Back</b></a></li></ul>');

        jQuery('.header-main-nav .menu-item-has-children').prepend('<span><i class="far fa-angle-right"></i></span>');

        $('.menu-item-has-children span').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('.sub-menu').addClass('in');
        });


        $('.back a').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('in');
        });

        $('.menu-back').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.sub-menu').removeClass('in');
        });


    }

   /* if ($(window).width() > 767) {
        var $sticky = jQuery('.sticky');
        var $stickyrStopper = jQuery('footer');

        if (!!$sticky.offset()) { // make sure ".sticky" element exists

            var generalSidebarHeight = $sticky.innerHeight();
            var stickyTop = $sticky.offset().top;
            var stickOffset = 70;
            var stickyStopperPosition = $stickyrStopper.offset().top;
            var stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset;
            var diff = stopPoint + stickOffset;

            $(window).scroll(function () { // scroll event
                var windowTop = $(window).scrollTop(); // returns number
                console.log('windowTop is ' + windowTop);
                console.log('stopPoint is ' + stopPoint);

                if (stopPoint < windowTop) {
                    $sticky.css({position: 'sticky', top: '20px', height: '100%'});
                }
            });

        }
    } */

// Smooth anchor scroll
    jQuery('a[href*="#"]:not([href="#"])').click(function () {
        var offset = -50; // <-- change the value here
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top + offset
                }, 1000);
                return false;
            }
        }
    });

});

jQuery(window).scroll(function()
{
    if ($(this).scrollTop() > 1000){
        $('#sticky-tab').addClass("top-show");
    }
    else {
        $('#sticky-tab').removeClass("top-show");
    }
});







